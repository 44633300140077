<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 4.38788C8 2.64184 9.60663 1.33821 11.3152 1.69789L24.8173 4.54024C26.0894 4.80802 27 5.93027 27 7.23023V15.2876C26.3821 14.9012 25.7109 14.592 25 14.3736V9.5C25 8.94772 24.5523 8.5 24 8.5H8C7.44772 8.5 7 8.94772 7 9.5V27C7 27.5523 7.44772 28 8 28H16.019C16.7091 28.8123 17.5492 29.4933 18.4964 30H8C6.34315 30 5 28.6569 5 27V9.5C5 7.84315 6.34315 6.5 8 6.5V4.38788ZM24.4053 6.49734C24.4653 6.50997 24.5223 6.52956 24.5756 6.55516C24.3893 6.51896 24.1969 6.5 24 6.5H10V4.38788C10 3.91217 10.4377 3.557 10.9032 3.65499L24.4053 6.49734Z"
      :fill="fill"
    />
    <path
      d="M22.5 15C23.0523 15 23.5 15.4477 23.5 16V21.5H29C29.5523 21.5 30 21.9477 30 22.5C30 23.0523 29.5523 23.5 29 23.5H23.5V29C23.5 29.5523 23.0523 30 22.5 30C21.9477 30 21.5 29.5523 21.5 29V23.5H16C15.4477 23.5 15 23.0523 15 22.5C15 21.9477 15.4477 21.5 16 21.5H21.5V16C21.5 15.4477 21.9477 15 22.5 15Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
