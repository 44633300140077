<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.32203 18.1503L9.32202 18.1503L5 20.4944V16.384C5 16.0788 4.88808 15.791 4.69659 15.569C3.63868 14.3423 3 12.747 3 11C3 7.13401 6.13401 4 10 4H14C17.866 4 21 7.13401 21 11C21 14.866 17.866 18 14 18H10C9.97642 18 9.95287 17.9999 9.92937 17.9997C9.72144 17.9976 9.51186 18.0474 9.32203 18.1503Z"
      :stroke="fill"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
  },
};
</script>
