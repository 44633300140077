<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 5H5V19H15V11V9V5ZM19 11H17V19H19V11ZM21 21L22 21V19L21 19V11V9H19H17V5V3H15H5H3V5V19H2V21H3H5H15H17H19H21ZM7 7H9V9H7V7ZM13 7H11V9H13V7ZM11 11H13V13H11V11ZM13 15H11V17H13V15ZM7 11H9V13H7V11ZM9 15H7V17H9V15Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
